import type { PublicKeyCredentialCreationOptionsJSON } from "@simplewebauthn/types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { startAuthentication } from "@simplewebauthn/browser";
import { Link, useNavigate } from "react-router-dom";

import type { LoginUserParams } from "@kuzco/models";
import { StatusCodes } from "@kuzco/models";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import { api } from "~/lib/trpc";
import PasswordInput from "~/ui/components/PasswordInput";
import Input from "~/ui/elements/Input";
import { withSuspense } from "~/ui/hoc/withSuspense";
import useTracking from "~/ui/hooks/useTracking.hook";
import useUser from "~/ui/hooks/useUser.hook";
import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import useIsAuthenticated from "../ui/hooks/useIsAuthenticated.hook";
import useLoginSuccessRedirect from "../ui/hooks/useLoginSuccessRedirect.hook";

const LoginPage = () => {
  const navigate = useNavigate();
  const { loginSuccessRedirect } = useLoginSuccessRedirect();
  const { login, authenticate } = useUser();
  const { isAuthenticated } = useIsAuthenticated();
  const toast = useToast();

  const [twoFactorToken, setTwoFactorToken] = useState("");
  const [requiresTwoFactor, setRequiresTwoFactor] = useState(false);

  const [u2fEnabled, setU2fEnabled] = useState(false);
  const [useU2F, setUseU2F] = useState(false);

  const [params, _setParams] = React.useState<LoginUserParams>({
    email: "",
    password: "",
    twoFactorToken: requiresTwoFactor ? twoFactorToken : undefined,
  });

  const [errors, setErrors] = React.useState<Record<string, object | null>>({});
  const { track } = useTracking();

  const setParams = (key: keyof LoginUserParams, value: string) => {
    _setParams((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: null }));
  };

  const generateU2FAuthenticationOptionsMutation =
    api.user.generateU2FAuthenticationOptions.useMutation();
  const verifyU2FAuthenticationMutation =
    api.user.verifyU2FAuthentication.useMutation();

  const verify2FAForLoginMutation = api.user.verifyOTPForLogin.useMutation();
  const checkU2FEnabledMutation = api.user.checkU2FEnabled.useMutation();

  const loginUser = useCallback(async () => {
    try {
      // First, check if the user has U2F enabled
      const u2fCheckResponse = await checkU2FEnabledMutation.mutateAsync({
        email: params.email,
      });

      setU2fEnabled(u2fCheckResponse.u2fEnabled);

      if (u2fCheckResponse.u2fEnabled) {
        // U2F authentication
        try {
          const response =
            await generateU2FAuthenticationOptionsMutation.mutateAsync({
              email: params.email,
            });

          let attResp;
          if (response.status === StatusCodes.OK && response.options) {
            attResp = await startAuthentication(
              response.options as PublicKeyCredentialCreationOptionsJSON,
            );
          } else {
            console.error("Error during authentication:", response.error);
            throw response.error;
          }

          const verificationResponse =
            await verifyU2FAuthenticationMutation.mutateAsync({
              email: params.email,
              response: attResp,
            });

          if (!verificationResponse.success) {
            toast({
              title: "Authentication failed",
              description: "U2F authentication failed. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        } catch (error) {
          console.error("U2F authentication error:", error);
          toast({
            title: "Authentication error",
            description:
              "An error occurred during U2F authentication. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      } else if (requiresTwoFactor && !useU2F) {
        // OTP Verification
        const verify2FAResponse = await verify2FAForLoginMutation.mutateAsync({
          email: params.email,
          code: twoFactorToken,
        });

        if (!verify2FAResponse.twoFactorVerified) {
          toast({
            title: "Invalid 2FA code",
            description: "Please enter a valid OTP code.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      // Proceed with login
      const response = await login.mutateAsync({
        email: params.email,
        password: params.password,
        twoFactorToken:
          requiresTwoFactor && !useU2F ? twoFactorToken : undefined,
      });

      if (response.error) {
        throw response.error;
      }

      if (!response.token || !response.user) {
        throw new Error("Failed to login");
      }

      if (response.user.otpSecret !== null && !requiresTwoFactor) {
        setRequiresTwoFactor(true);
        return;
      }

      track({
        event: PosthogEvent.AuthLogin,
      });

      _setParams({
        email: response.user.email,
        password: params.password ? params.password : response.user.email,
      });

      authenticate({ user: response.user, token: response.token });

      if (
        response.user.emailVerificationRequired &&
        response.user.emailVerifiedAt == null
      ) {
        navigate("/verify-email");
        return;
      }

      // Login successful
      await loginSuccessRedirect();
    } catch (e1: unknown) {
      try {
        // @ts-expect-error - types need improvement
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const json = JSON.parse(e1.message);
        setErrors(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          json.reduce(
            (cur: Record<string, object>, next: unknown) => ({
              ...cur,
              // @ts-expect-error - types need improvement
              [next.path]: next,
            }),
            {},
          ),
        );
        toast({
          title: "Invalid Fields",
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } catch (e2) {
        toast({
          title: "Error Logging In",
          description: (e1 as Error).message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [
    authenticate,
    login,
    loginSuccessRedirect,
    navigate,
    params,
    toast,
    track,
    requiresTwoFactor,
    twoFactorToken,
    verify2FAForLoginMutation,
    checkU2FEnabledMutation,
    generateU2FAuthenticationOptionsMutation,
    verifyU2FAuthenticationMutation,
    useU2F,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      void loginSuccessRedirect();
    }
  }, [isAuthenticated, loginSuccessRedirect]);

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Link to="/">
            {/* <Image
            alt="Kuzco Logo"
            src={'/images/context-mark.svg'}
            width="48"
            height="48"
            style={{
              maxWidth: '48px',
              height: '48px',
            }}
          /> */}
          </Link>
          <Box height="24px" />
          <Text variant="30-bold" color="white" marginLeft="16px">
            Log in to your account
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400" marginLeft="16px">
            Welcome back! Please enter your details.
          </Text>
          <Box height="32px" />
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Stack width="100%" spacing="20px">
              <Input
                id="email"
                isDisabled={login.isLoading}
                label="Email"
                type="email"
                width="100%"
                size="lg"
                value={params.email}
                placeholder="Enter your email"
                onChange={(e) => setParams("email", e.currentTarget.value)}
                isInvalid={errors.email ? true : false}
                autoFocus
              />
              <PasswordInput
                id="password"
                isDisabled={login.isLoading}
                label="Password"
                width="100%"
                size="lg"
                value={params.password}
                placeholder="••••••••"
                onChange={(e) => setParams("password", e.currentTarget.value)}
                isInvalid={errors.password ? true : false}
              />
            </Stack>
            <Stack width="100%" spacing="0">
              <Box height="24px" />
              {login.isLoading ? (
                <Flex alignItems="center" justify="center" height="104px">
                  <Spinner size="lg" />
                </Flex>
              ) : (
                <Flex flexDir={"column"} gridGap={"24px"}>
                  {u2fEnabled && requiresTwoFactor && (
                    <Stack spacing={2}>
                      <Text>Choose your authentication method:</Text>
                      <Flex justifyContent="space-between">
                        <Button
                          onClick={() => setUseU2F(true)}
                          isActive={useU2F}
                          width="48%"
                        >
                          Use U2F
                        </Button>
                        <Button
                          onClick={() => setUseU2F(false)}
                          isActive={!useU2F}
                          width="48%"
                        >
                          Use OTP
                        </Button>
                      </Flex>
                    </Stack>
                  )}

                  {requiresTwoFactor && !useU2F && (
                    <Input
                      id="twoFactorToken"
                      isDisabled={login.isLoading}
                      label="Two-Factor Authentication Code"
                      width="100%"
                      size="lg"
                      value={twoFactorToken}
                      placeholder="Enter your 2FA code"
                      onChange={(e) => setTwoFactorToken(e.currentTarget.value)}
                    />
                  )}
                  <Button
                    variant="primary"
                    size="lg"
                    width="100%"
                    onClick={() => loginUser()}
                    isLoading={login.isLoading}
                    type="submit"
                  >
                    {u2fEnabled && useU2F
                      ? "Authenticate with U2F"
                      : requiresTwoFactor
                        ? "Authenticate with OTP"
                        : "Sign in"}
                  </Button>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Flex>
                      <Checkbox />
                      <Text variant="14-semi" color="white" marginLeft="8px">
                        Remember for 30 days
                      </Text>
                    </Flex>
                    <Flex>
                      <Link to="/forgot-password">
                        <Text variant="14-bold" color="#2970FF">
                          Forgot password
                        </Text>
                      </Link>
                    </Flex>
                  </Flex>

                  <Text variant="14-reg" color="white">
                    Don&apos;t have an account?{" "}
                    <Link to={"/register"}>
                      <Text as="span" variant="14-bold" color="#2970FF">
                        Sign up
                      </Text>
                    </Link>
                  </Text>
                </Flex>
              )}
            </Stack>
          </form>
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(LoginPage);
