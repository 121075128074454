import { DateTime, Duration } from "luxon";

export { DateTime };

const DEFAULT_TIMEZONE = "Etc/UTC";

const currentBrowserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const now = (timezone: string = DEFAULT_TIMEZONE): number => {
  return DateTime.fromMillis(DateTime.now().toMillis(), {
    zone: timezone,
  }).toMillis();
};

const currentDate = (timezone: string = DEFAULT_TIMEZONE): DateTime => {
  return DateTime.now().setZone(timezone);
};

const date = (
  millis: number | string,
  timezone = DEFAULT_TIMEZONE,
): DateTime => {
  if (typeof millis === "string") millis = Number(millis);
  return DateTime.fromMillis(millis, { zone: timezone });
};

/**
 * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
const format = (
  millis: number | string,
  format = "DD",
  timezone: string = DEFAULT_TIMEZONE,
): string => {
  if (typeof millis === "string") millis = Number(millis);
  return date(millis, timezone).toFormat(format);
};

function formatConversationTime(echoMillis: number): string {
  const inputDate = new Date(echoMillis);
  const today = new Date();

  // Check if the input date is today
  if (
    new Date(echoMillis).setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
  ) {
    // Format as time if the date is today
    let hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

    return `${hours}:${minutesStr} ${ampm}`;
  } else {
    // Format as abbreviated month and day if the date is not today
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate();

    return `${month} ${day}`;
  }
}

const minutesBack = (minutes: number): Date => {
  return new Date(Date.now() - minutes * 60 * 1000);
};

const getDaysBackFromMilliseconds = (timeInMs: number): number => {
  const currentTimeMs = Date.now();
  const differenceMs = currentTimeMs - timeInMs;
  const dayMs = 24 * 60 * 60 * 1000;
  return Math.floor(differenceMs / dayMs);
};

const daysToSeconds = (days: number): number => {
  return days * 24 * 60 * 60;
};

const msFromMinutes = (minutes: number): number => {
  return minutes * 60 * 1000;
};

export const time = {
  now,
  date,
  format,
  formatConversationTime,
  currentBrowserTimezone,
  currentDate,
  Duration,
  minutesBack,
  getDaysBackFromMilliseconds,
  daysToSeconds,
  msFromMinutes,
};
