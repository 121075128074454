import { z } from "zod";

export const WorkerInstallTypeSchema = z.enum([
  "Desktop App",
  "Desktop Windows",
  "CLI",
  "Docker",
]);

export const WorkerSchema = z.object({
  _id: z.string(),
  userId: z.string(),
  teamId: z.string(),
  name: z.string(),
  installType: WorkerInstallTypeSchema,
  registrationCode: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  isArchived: z.boolean().optional(),
  isDisabled: z.boolean().optional(),
  shouldAutoStart: z.boolean().optional(),
});
