import * as React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  FiAlertCircle,
  FiAperture,
  FiBarChart,
  FiDollarSign,
  FiFile,
  FiHome,
  FiInfo,
  FiLock,
  FiMail,
  FiMessageSquare,
  FiPieChart,
  FiTwitter,
  FiUser,
} from "react-icons/fi";
import { Link } from "react-router-dom";

import type { User } from "@kuzco/models";
import { isSuperUser } from "@kuzco/utils";

import DiscordLogo from "~/assets/discord.svg";
import KuzcoLogo from "~/assets/kuzco-white.svg";
// import TwitterLogo from "~/assets/twitter.svg";
import {
  CONSOLE_FULL_VERSION,
  DISCORD_CLIENT_ID,
  DISCORD_INVITE_LINK,
  DISCORD_REDIRECT_URI,
  DOCUMENTATION_LINK,
  DOWNLOAD_LINK,
  TWITTER_LINK,
} from "~/lib/const";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import useFlagEnabled from "../../hooks/useFlagEnabled.hook";
import AuthMenu from "../AuthMenu";
import DebugButton from "../DebugButton";
import HelpButton from "../HelpButton";
import { NavButton } from "./NavButton";

const OrganizationDetails = ({ user }: { user?: User }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        border="1px solid"
        borderColor="gray.800"
        padding="8px 12px"
        borderRadius="50%"
        width="40px"
        height="40px"
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          borderRadius="50%"
          color="white"
          fontWeight="bold"
          fontSize="16px"
        >
          {user?.firstName.charAt(0)}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid"
      borderColor="gray.800"
      padding="8px 12px"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          <Image
            alt="Kuzco Logo"
            src={KuzcoLogo}
            width="20"
            height="20"
            style={{
              maxWidth: "20px",
              height: "20px",
            }}
          />
        </Link>
        <Box width="8px" />
        <Text
          variant="14-bold"
          color="white"
          maxWidth="128px"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {user?.firstName} {user?.lastName}
        </Text>
      </Box>
      <ChevronDownIcon color="gray.600" fontSize="24px" />
    </Box>
  );
};

const SideNavigation: React.FC = () => {
  const solanaWalletEnabledFlag = useFlagEnabled("solanaWalletEnabled", false);
  const isMobile = useIsMobile();
  const { user } = useUser();
  const { isTauri } = useAppConfig();
  const [isCollapsed, _setIsCollapsed] = React.useState(
    useBreakpointValue({ base: false, md: true }),
  );

  const handleLinkDiscord = () => {
    const state = user?._id;
    const url = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(DISCORD_REDIRECT_URI)}&response_type=code&scope=identify+email&state=${state}`;
    window.location.href = url;
  };

  return (
    <Flex
      as="section"
      minH="100vh"
      bg="gray.1100"
      borderRight="1px solid"
      borderColor="gray.800"
      width={isMobile ? "50px" : "240px"}
      flexShrink={0}
      padding={isMobile ? "5px" : "16px"}
      transition="width 0.3s"
    >
      <Flex flex="1" direction="column" justifyContent="space-between">
        <Box width="fill-available" flex="1" position="relative">
          {isMobile ? <Box height="8px" /> : null}
          <AuthMenu>
            <OrganizationDetails user={user ?? undefined} />
          </AuthMenu>
          <Box height="24px" />
          <Box flex="1">
            <NavButton
              label="Dashboard"
              icon={FiHome}
              link="/dashboard/overview"
              isCollapsed={isCollapsed}
            />
            <NavButton
              label="My Stats"
              icon={FiBarChart}
              link="/dashboard/my-stats"
              isCollapsed={isCollapsed}
            />
            <NavButton
              label="Leaderboard"
              icon={FiPieChart}
              link="/leaderboard"
              isCollapsed={isCollapsed}
            />
            <NavButton
              label="Workers"
              icon={FiAperture}
              link="/dashboard/workers"
              isCollapsed={isCollapsed}
            />
            <NavButton
              label="API Keys"
              icon={FiLock}
              link="/dashboard/keys"
              isCollapsed={isCollapsed}
            />
            <NavButton
              label="Wallet"
              icon={FiDollarSign}
              link="/dashboard/wallet"
              tag={!solanaWalletEnabledFlag ? "coming soon" : undefined}
              isCollapsed={isCollapsed}
            />
            {isSuperUser(user?.email ?? "") && (
              <NavButton
                label="Super Admin"
                icon={FiAlertCircle}
                link="/dashboard/super/users"
                isActiveLink={(path) => path.includes("/super")}
                isCollapsed={isCollapsed}
              />
            )}
            <Box height="32px" />
            {user != null && user.username == null && (
              <NavButton
                label="Set Username"
                icon={FiUser}
                link="/set-username"
                isCollapsed={isCollapsed}
              />
            )}
            {user != null && user.discordId == null && (
              <NavButton
                label="Link Discord"
                icon={FiMessageSquare}
                image={DiscordLogo}
                onClick={handleLinkDiscord}
                isActiveLink={(_path) => false}
                isExternal
                isCollapsed={isCollapsed}
              />
            )}
            {user != null &&
              !user.emailVerificationRequired &&
              user.emailVerifiedAt == null && (
                <NavButton
                  label="Verify Email"
                  icon={FiMail}
                  link="/verify-email"
                  isCollapsed={isCollapsed}
                />
              )}
            <Box height="16px" />
            <Box bottom="0px" position="absolute" padding="10px">
              <HelpButton
                text="Discord"
                Icon={FiMessageSquare}
                link={DISCORD_INVITE_LINK}
              />
              <HelpButton text="Twitter" Icon={FiTwitter} link={TWITTER_LINK} />
              <HelpButton
                text="View Documentation"
                Icon={FiFile}
                onClick={() => {
                  window.open(DOCUMENTATION_LINK, "_blank");
                }}
              />
              <DebugButton />
              {isTauri && (
                <HelpButton
                  text={CONSOLE_FULL_VERSION}
                  Icon={FiInfo}
                  onClick={() => {
                    window.open(DOWNLOAD_LINK, "_blank");
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SideNavigation;
