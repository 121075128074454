import { Suspense } from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { api } from "~/lib/trpc";
import CardsChart from "~/ui/components/metrics/Cards.chart";
import GenerationCountChart from "~/ui/components/metrics/GenerationCount.chart";
import TokenEarningsChart from "~/ui/components/metrics/TokenEarnings.chart";
import SystemTime from "~/ui/components/SystemTime";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import PageLayoutSuspense from "~/ui/suspense/PageLayout.suspense";
import StatsSuspense from "~/ui/suspense/Stats.suspense";

const MyStatsPageComponent = () => {
  const [data] = api.metrics.user.useSuspenseQuery({
    userId: true,
  });

  if (data === null) {
    return <MyStatsPageSuspense />;
  }

  return (
    <>
      <Header>
        <Flex alignItems="center">
          <Title>Earning Statistics</Title>
          <Box width="16px" />
        </Flex>
        <SystemTime />
      </Header>
      <Content>
        {(() => {
          return (
            <>
              <Box height="24px" />
              <CardsChart
                values={[
                  {
                    title: "24H Inference Requests",
                    value: data.generationsLast24Hours,
                    subtitle: "Updated every hour",
                  },
                  {
                    title: "24H $KZO Points",
                    value: data.tokensLast24Hours,
                    subtitle: "Updated every hour",
                    isKuzcoValue: true,
                  },
                  {
                    title: "Total $KZO Points",
                    value: data.tokensAllTime,
                    subtitle: "Updated every hour",
                    isKuzcoValue: true,
                  },
                ]}
              />
              <Box height="24px" />
              <TokenEarningsChart
                title="Earning Statistics"
                metric={data.tokensLast30DaysBarChart}
              />
              <Box height="24px" />
              <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                <GridItem>
                  <GenerationCountChart
                    // @ts-expect-error need better types here.
                    metric={data.generationsLast24HoursLineChart}
                  />
                </GridItem>
              </Grid>
            </>
          );
        })()}
        <Box height="200px" />
      </Content>
    </>
  );
};

function MyStatsPageSuspense() {
  return (
    <PageLayoutSuspense pageTitle="My Stats">
      <StatsSuspense />
    </PageLayoutSuspense>
  );
}

export default function MyStatsPage() {
  return (
    <Suspense fallback={<MyStatsPageSuspense />}>
      <MyStatsPageComponent />
    </Suspense>
  );
}
