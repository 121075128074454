import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { FiCopy, FiLock, FiTrash } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { useCopyToClipboard } from "usehooks-ts";

import type { Key } from "@kuzco/models";
import { time } from "@kuzco/utils";

import useKey from "~/ui/hooks/useKey.hook";
import useModal from "~/ui/hooks/useModal.hook";
import { ConfirmModalTypes } from "../modal/Confirm.modal";
import { ModalTypes } from "../modal/Modal";

const InnerTable = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Table variant="simple">
      <Thead
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.800"
      >
        <Tr backgroundColor="gray.1000" borderColor="gray.800">
          <Th borderColor="gray.800" color="white">
            Name
          </Th>
          <Th borderColor="gray.800" color="white">
            Key
          </Th>
          <Th borderColor="gray.800" color="white">
            Last Used
          </Th>
          <Th borderColor="gray.800" color="white">
            Created
          </Th>
          <Th borderColor="gray.800" color="white" width="100px" />
        </Tr>
      </Thead>
      {children && children}
    </Table>
  );
};

const MockTable = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box overflow="hidden" height="100%" width="100%">
      <InnerTable />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 150px)"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
};

type KeysTableProps = {
  keyList: Key[];
};

const KeysTable: React.FC<KeysTableProps> = ({ keyList }) => {
  const { push, pop } = useModal();
  const { archiveKey } = useKey();
  const [, copy] = useCopyToClipboard();
  const toast = useToast();

  const copyKey = React.useCallback(
    (key: string) => {
      ReactTooltip.hide();
      void copy(key);
      toast({
        title: "API Key Copied!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    [copy, toast],
  );

  return (
    <TableContainer overflow="scroll" borderColor="gray.800">
      {(() => {
        if (!keyList.length) {
          return (
            <MockTable>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                marginTop="64px"
              >
                <Icon as={FiLock} color="gray.400" fontSize="40px" />
                <Flex height="8px" />
                <Flex height="16px" />
                <Button
                  variant="primary"
                  width="240px"
                  size="sm"
                  onClick={() =>
                    push({
                      type: ModalTypes.CreateAPIKey,
                      props: {},
                    })
                  }
                >
                  Create API Key
                </Button>
              </Box>
            </MockTable>
          );
        }

        return (
          <InnerTable>
            <Tbody overflow="hidden">
              {keyList.map((key: Key, index: number) => {
                const Column = ({
                  children,
                }: {
                  children: React.ReactNode;
                }) => {
                  return (
                    <Td
                      fontSize="14px"
                      lineHeight="20px"
                      fontWeight="400"
                      height="60px"
                      padding="0 24px"
                      maxWidth="300px"
                      whiteSpace="nowrap"
                      borderColor="gray.800"
                      color="white"
                    >
                      {children}
                    </Td>
                  );
                };
                return (
                  <Tr
                    key={index}
                    height="80px"
                    borderColor="gray.800"
                    border="0px"
                  >
                    <Column>{key.name}</Column>
                    <Column>
                      <Flex
                        data-tip="Copy API Key"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        onClick={() => copyKey(key.key)}
                        _hover={{ cursor: "pointer" }}
                      >
                        {key.key.substring(0, 8)}...
                        {key.key.substring(36)}
                      </Flex>
                    </Column>
                    <Column>
                      {key.lastUsedAt
                        ? time.format(
                            key.lastUsedAt.getTime(),
                            "f",
                            time.currentBrowserTimezone(),
                          )
                        : "Never"}
                    </Column>
                    <Column>
                      {time.format(
                        key.createdAt.getTime(),
                        "f",
                        time.currentBrowserTimezone(),
                      )}
                    </Column>
                    <Column>
                      <Flex alignItems="center" justifyContent="center">
                        <Button
                          data-tip="Copy API Key"
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            copyKey(key.key);
                          }}
                        >
                          <Icon as={FiCopy} />
                        </Button>
                        <Box width="8px" />
                        <Button
                          data-tip="Delete API Key"
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            ReactTooltip.hide();
                            push({
                              type: ModalTypes.Confirm,
                              props: {
                                title: `Delete API Key ${key.name}`,
                                type: ConfirmModalTypes.Danger,
                                message: `You are about to delete API key ${key.name}. Applications using this key will no longer be able to access the network. Are you sure you want to continue?`,
                                confirm: async () => {
                                  await archiveKey.mutateAsync({
                                    _id: key._id,
                                  });

                                  pop();
                                },
                                cancel: () => {
                                  pop();
                                },
                                mutation: archiveKey,
                              },
                            });
                          }}
                        >
                          <Icon as={FiTrash} />
                        </Button>
                      </Flex>
                    </Column>
                  </Tr>
                );
              })}
            </Tbody>
          </InnerTable>
        );
      })()}
    </TableContainer>
  );
};

export default KeysTable;
