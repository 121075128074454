import React, { Suspense } from "react";
import {
  Box,
  Flex,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaTrophy } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

import { StatusCodes } from "@kuzco/models";
import { numbers } from "@kuzco/utils";

import { api } from "~/lib/trpc";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import MarketingLayout from "~/ui/layouts/Marketing.layout";

const InnerTable = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Table variant="simple">
      <Thead
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.800"
        height="44px"
      >
        <Tr backgroundColor="gray.1000" borderColor="gray.800">
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
          >
            Rank
          </Th>
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
          >
            Username
          </Th>
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
            isNumeric
          >
            Points
          </Th>
        </Tr>
      </Thead>
      {children}
    </Table>
  );
};

const getRowBackgroundColor = (index: number) => {
  switch (index) {
    case 0:
      return "gray.900";
    case 1:
      return "gray.1000";
    case 2:
      return "gray.1000";
    default:
      return undefined;
  }
};

const getRowTextColor = (index: number) => {
  switch (index) {
    case 0:
      return "yellow.400";
    case 1:
      return "gray.100";
    case 2:
      return "gray.200";
    default:
      return "white";
  }
};

const LeaderboardContent: React.FC = () => {
  const [leaderboardResponse] = api.user.getLeaderboard.useSuspenseQuery();
  const isMobile = useIsMobile();

  const totalPoints = React.useMemo(() => {
    const calculateTotalPoints = (
      leaderboard: typeof leaderboardResponse.leaderboard,
    ) => leaderboard.reduce((total, entry) => total + Number(entry.balance), 0);

    return calculateTotalPoints(leaderboardResponse.leaderboard);
  }, [leaderboardResponse]);

  return (
    <TableContainer
      overflow="auto"
      border="1px solid"
      borderColor="gray.800"
      borderRadius="8px"
      backgroundColor="gray.1100"
      maxWidth="100%"
      minWidth={isMobile ? "100%" : "650px"}
    >
      <Box
        padding="24px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          <Text color="white" variant="20-semi">
            Points Leaderboard
          </Text>
          <Text color="gray.300" fontSize="14px" fontWeight="medium" mt={1}>
            Top 200
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="flex-end">
          <Text color="white" variant="14-semi">
            Updated every 24 hours
          </Text>
          <Text color="gray.300" fontSize="14px" fontWeight="medium" mt={1}>
            Total Points: {numbers.shorten(totalPoints)}
          </Text>
        </Flex>
      </Box>
      {(() => {
        if (leaderboardResponse.status === StatusCodes.UNKNOWN_CODE) {
          return (
            <Flex height="300px" alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          );
        }

        if (!leaderboardResponse.leaderboard.length) {
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="300px"
            >
              <Icon as={FiSearch} color="gray.400" fontSize="40px" />
              <Flex height="16px" />
              <Text color="gray.500" fontSize="18px" fontWeight="500">
                No leaderboard data available
              </Text>
            </Box>
          );
        }

        return (
          <InnerTable>
            <Tbody overflow="hidden">
              {leaderboardResponse.leaderboard.map((entry, index) => (
                <Tr key={index} backgroundColor={getRowBackgroundColor(index)}>
                  <Td
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="400"
                    height="60px"
                    padding="0 24px"
                    maxWidth="300px"
                    whiteSpace="nowrap"
                    borderColor="gray.800"
                    color={getRowTextColor(index)}
                  >
                    {index === 0 ? (
                      <Flex alignItems="center">
                        <Icon
                          as={FaTrophy}
                          color="yellow.400"
                          boxSize={4}
                          mr={2}
                        />
                        #{index + 1}
                      </Flex>
                    ) : (
                      `#${index + 1}`
                    )}
                  </Td>
                  <Td
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight={index < 3 ? "600" : "400"}
                    height="60px"
                    padding="0 24px"
                    maxWidth="300px"
                    whiteSpace="nowrap"
                    borderColor="gray.800"
                    color={getRowTextColor(index)}
                  >
                    {entry.username ?? "Anonymous"}
                  </Td>
                  <Td
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight={index < 3 ? "600" : "400"}
                    height="60px"
                    padding="0 24px"
                    maxWidth="300px"
                    whiteSpace="nowrap"
                    borderColor="gray.800"
                    color={getRowTextColor(index)}
                    isNumeric
                  >
                    {numbers.shorten(Number(entry.balance))}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </InnerTable>
        );
      })()}
    </TableContainer>
  );
};

const LeaderboardPage: React.FC = () => {
  return (
    <MarketingLayout>
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Suspense
          fallback={
            <Flex height="300px" alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          }
        >
          <LeaderboardContent />
        </Suspense>
      </Flex>
    </MarketingLayout>
  );
};

export default LeaderboardPage;
