import { z } from "zod";

import { authedServiceRequest, serviceResponse } from "./BaseService";

/** ******************************************************************************
 *  Types
 ******************************************************************************* */

const TransactionStatusEnum = z.enum(["PENDING", "REVERSED", "FINAL"]);

const TransactionTypeEnum = z.enum([
  "USER_REGISTRATION_ALLOWANCE",
  "TEAM_REGISTRATION_ALLOWANCE",
  "TOKENS_PURCHASED",
  "INFERENCE_ESCROW",
  "INFERENCE_ESCROW_REFUND",
  "INFERENCE_SPEND",
  "INFERENCE_PAYMENT",
]);

export const TransactionSchema = z.object({
  _id: z.string(),
  teamId: z.string(),
  userId: z.string().nullish(),
  apiKeyId: z.string().nullish(),
  workerId: z.string().nullish(),
  generationId: z.string().nullish(),
  status: TransactionStatusEnum,
  type: TransactionTypeEnum,
  points: z.bigint(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Transaction = z.infer<typeof TransactionSchema>;

/** ******************************************************************************
 *  Transaction Storage Interface
 ******************************************************************************* */

export type TransactionStorageInterface = {
  getTeamAvailableBalance(teamId: string): Promise<bigint>;
  getTeamFinalBalance(teamId: string): Promise<bigint>;
  getLeaderboard(): Promise<{ username: string | null; balance: bigint }[]>;
};

/** ******************************************************************************
 *  Get Team Available Balance
 ******************************************************************************* */

export const getTeamAvailableBalanceParams = z.object({
  teamId: z.string(),
});

export const getTeamAvailableBalanceRequest = authedServiceRequest.merge(
  z.object({
    params: getTeamAvailableBalanceParams,
  }),
);

export const getTeamAvailableBalanceResponse = serviceResponse.merge(
  z.object({
    availableBalance: z.bigint().optional(),
  }),
);

export type GetTeamAvailableBalanceParams = z.infer<
  typeof getTeamAvailableBalanceParams
>;
export type GetTeamAvailableBalanceRequest = z.infer<
  typeof getTeamAvailableBalanceRequest
>;
export type GetTeamAvailableBalanceResponse = z.infer<
  typeof getTeamAvailableBalanceResponse
>;

/** ******************************************************************************
 *  Get Team Final Balance
 ******************************************************************************* */

export const getTeamFinalBalanceParams = z.object({
  teamId: z.string(),
});

export const getTeamFinalBalanceRequest = authedServiceRequest.merge(
  z.object({
    params: getTeamFinalBalanceParams,
  }),
);

export const getTeamFinalBalanceResponse = serviceResponse.merge(
  z.object({
    finalBalance: z.bigint().optional(),
  }),
);

export type GetTeamFinalBalanceParams = z.infer<
  typeof getTeamFinalBalanceParams
>;
export type GetTeamFinalBalanceRequest = z.infer<
  typeof getTeamFinalBalanceRequest
>;
export type GetTeamFinalBalanceResponse = z.infer<
  typeof getTeamFinalBalanceResponse
>;

/** ******************************************************************************
 *  Get Leaderboard
 ******************************************************************************* */

export const getLeaderboardResponse = serviceResponse.merge(
  z.object({
    leaderboard: z.array(
      z.object({
        username: z.string().nullable(),
        balance: z.bigint(),
      }),
    ),
  }),
);

export type GetLeaderboardResponse = z.infer<typeof getLeaderboardResponse>;

/** ******************************************************************************
 *  Transactions Service Interface
 ******************************************************************************* */

export type TransactionServiceInterface = {
  getTeamAvailableBalance(
    request: GetTeamAvailableBalanceRequest,
  ): Promise<GetTeamAvailableBalanceResponse>;
  getTeamFinalBalance(
    request: GetTeamFinalBalanceRequest,
  ): Promise<GetTeamFinalBalanceResponse>;
  getLeaderboard(): Promise<GetLeaderboardResponse>;
};
