import type { PublicKeyCredentialCreationOptionsJSON } from "@simplewebauthn/types";
import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { startRegistration } from "@simplewebauthn/browser";

import { StatusCodes } from "@kuzco/models";

import { api } from "~/lib/trpc";
import useUser from "~/ui/hooks/useUser.hook";

type U2FAuthModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const U2FAuthModal: React.FC<U2FAuthModalProps> = ({ isOpen, onClose }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [keyLabel, setKeyLabel] = useState("");
  const { user, refreshUser } = useUser();
  const generateU2FRegistrationOptionsMutation =
    api.user.generateU2FRegistrationOptions.useMutation();
  const verifyU2FRegistrationMutation =
    api.user.verifyU2FRegistration.useMutation();
  const toast = useToast();

  const handleRegisterU2F = async () => {
    try {
      if (!keyLabel.trim()) {
        toast({
          title: "Error",
          description: "Please provide a label for your security key",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!user?.email) {
        throw new Error("User email is not available");
      }
      setIsRegistering(true);
      const response = await generateU2FRegistrationOptionsMutation.mutateAsync(
        {
          email: user.email,
        },
      );

      let attResp;

      if (response.status === StatusCodes.OK && response.options) {
        // Use startRegistration from SimpleWebAuthnBrowser
        attResp = await startRegistration(
          response.options as PublicKeyCredentialCreationOptionsJSON,
        );
      } else {
        console.error("Error during registration:", response.error);
        throw response.error;
      }

      const verifyResponse = await verifyU2FRegistrationMutation.mutateAsync({
        credential: attResp,
        userLabel: keyLabel,
      });

      if (verifyResponse.status === StatusCodes.OK) {
        await refreshUser();
        setKeyLabel("");
        onClose();
        toast({
          title: "Success",
          description: "U2F enabled successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to verify U2F registration");
      }
    } catch (error) {
      console.error("Error registering U2F:", error);
      setKeyLabel("");
      toast({
        title: "Error",
        description: "Failed to register U2F device",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="gray.1000">
        <ModalHeader>Universal 2nd Factor (U2F)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Text>
              Enter a label for your security key and click the button below to
              register it.
            </Text>
            <Input
              placeholder="Security Key Label"
              value={keyLabel}
              onChange={(e) => setKeyLabel(e.target.value)}
            />
            <Button
              onClick={handleRegisterU2F}
              isLoading={isRegistering}
              isDisabled={!keyLabel.trim()}
            >
              Register U2F Device
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default U2FAuthModal;
