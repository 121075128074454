import React, { useCallback, useMemo } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import type { WorkerWithInstances } from "@kuzco/models";
import { time } from "@kuzco/utils";

import KuzcoLogo from "~/assets/kuzco-white.svg";
import { DISCORD_INVITE_LINK } from "~/lib/const";
import WorkerCard from "~/ui/components/worker/WorkerCard";
import { withSuspense } from "~/ui/hoc/withSuspense";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";
import useUser from "~/ui/hooks/useUser.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import DashboardLayout from "~/ui/layouts/Dashboard.layout";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";

const VerifyTauriInstallation = () => {
  const { workerManager } = useAppConfig();
  const navigate = useNavigate();
  const { user } = useUser();
  const [isVerified, setIsVerified] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [status, setStatus] = React.useState(
    "Downloading weights. This may take a while...",
  );
  const [percentage, setPercentage] = React.useState<string | false>(false);
  const { createWorker } = useWorker("");

  const mockWorker: WorkerWithInstances = useMemo(() => {
    return {
      _id: "FAKE",
      userId: user?._id ?? "",
      teamId: user?._id ?? "",
      name: "Fake name",
      installType: "Desktop App",
      status: "Offline",
      instances: [],
      registrationCode: null,
      info: null,
      poolAssignments: [],
      createdAt: new Date(time.now()),
      updatedAt: new Date(time.now()),
      lastHeartbeatAt: 0,
    };
  }, [user]);

  const create = useCallback(async () => {
    try {
      const { worker } = await createWorker.mutateAsync(mockWorker, {
        onError: (e) => {
          console.error(e);
          if (e instanceof Error) {
            setError(e.message);
          }
        },
      });

      if (!worker) {
        throw new Error("Failed to create worker.");
      }

      return workerManager?.runFromWorkerParams(worker);
    } catch (e) {
      console.error(`Failed to create worker`, e);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }, [createWorker, workerManager, mockWorker]);

  const verify = useMutation({
    mutationKey: ["verifyOllama"],
    mutationFn: async () => {
      try {
        const workerClient = await create();

        if (!workerClient) {
          throw new Error("Failed to create worker client.");
        }

        // setStatus("Loading test weights: ");

        // console.log(workerClient.progress);

        // await wait.forTrue(() => Boolean(workerClient.progress));

        // while (workerClient.progress) {
        //   await wait.timeout(100);
        //   setPercentage(
        //     workerClient.progress.percentage
        //       ? workerClient.progress.percentage
        //       : "100%",
        //   );
        // }

        setPercentage(false);

        setStatus("Testing inference..");

        setIsVerified(true);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    },
  });

  return (
    <DashboardLayout hideNavigation>
      <>
        <Header>
          <Title>
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Image
                  alt="Kuzco Logo"
                  src={KuzcoLogo}
                  width="24px"
                  height="24px"
                  maxWidth="32px"
                  borderRadius="6px"
                />
                <Box width="12px" />
                <Title>Kuzco Console</Title>
              </Flex>
            </Flex>
          </Title>
          <Link href={DISCORD_INVITE_LINK} target="_blank">
            <Button
              variant="secondary"
              size="sm"

              // onClick={() => {
              // push({
              //   type: ModalTypes.Confirm,
              //   props: {
              //     title: `Cancel`,
              //     type: ConfirmModalTypes.Warning,
              //     message: `Kuzco may not work properly if you skip this step. Are you sure you want to continue?`,
              //     confirm: async () => {
              //       navigate("/welcome");
              //       pop();
              //     },
              //     cancel: () => {
              //       pop();
              //     },
              //   },
              // });
              // }}
            >
              Help
            </Button>
          </Link>
        </Header>
        <Content>
          <Box display="flex" paddingTop="40px" justifyContent="center">
            <Box width="600px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text variant="24-reg" color="white">
                    Verify Installation
                  </Text>
                  <Box height="4px" />
                  <Text variant="16-reg" color="gray.400">
                    Verify Kuzco Console is running properly before joining the
                    network.
                  </Text>
                </Box>
              </Box>
              <Box margin="24px 0px 24px">
                <Divider height="1px" backgroundColor="gray.800" />
              </Box>
              <WorkerCard
                onClick={() => null}
                worker={mockWorker}
                columns={3}
              />
              <Box height="24px" />
              <Text variant="18-med" marginBottom="4px">
                1. Click 'Verify Installation'
              </Text>
              <Text variant="16-reg" marginBottom="16px" color="gray.400">
                We will run your computer through a series of tests to ensure
                that its Worker settings are configured properly. This process
                may take a few minutes.
              </Text>
              <Box height="16px" />
              <Text variant="18-med" marginBottom="4px">
                2. Join the Network
              </Text>
              <Text variant="16-reg" marginBottom="16px" color="gray.400">
                Once your installation is verified, join the network by clicking
                the button below.
              </Text>
              <Box height="24px" />
              <Flex justifyContent="flex-start">
                {(() => {
                  if (isVerified) {
                    return (
                      <Flex alignItems="center">
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="primary"
                            size="lg"
                            onClick={() => navigate("/welcome")}
                            margin="0"
                          >
                            Join Network
                          </Button>
                        </Flex>
                        <Box width="16px" />
                        <Text variant="18-med">Kuzco is running!</Text>
                        <Box width="8px" />
                        <CheckCircleIcon color="green.500" fontSize="20px" />
                      </Flex>
                    );
                  }
                  if (error) {
                    return (
                      <Flex alignItems="center">
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="primary"
                            size="lg"
                            isLoading={verify.isLoading}
                            onClick={async () => {
                              setError(null);
                              setIsVerified(false);
                              await verify.mutateAsync();
                            }}
                          >
                            Try Again
                          </Button>
                        </Flex>
                        <Box width="16px" />
                        <Text variant="18-med" color="red.500">
                          Error:&nbsp;{error}. Please close Kuzo and try again.
                        </Text>
                      </Flex>
                    );
                  }

                  return (
                    <Flex alignItems="center">
                      <Flex justifyContent="flex-end">
                        <Button
                          variant="primary"
                          size="lg"
                          isLoading={verify.isLoading}
                          isDisabled={verify.isLoading}
                          onClick={async () => {
                            await verify.mutateAsync();
                          }}
                        >
                          Verify Installation
                        </Button>
                      </Flex>
                      <Box width="16px" />
                      {verify.isLoading ? (
                        <>
                          {" "}
                          <Text variant="18-reg">{status}</Text>
                          <Box width="4px" />
                          {percentage ? (
                            <Text variant="18-reg">{percentage}</Text>
                          ) : null}
                        </>
                      ) : null}
                    </Flex>
                  );
                })()}
              </Flex>
              <Box height="300px" />
            </Box>
          </Box>
        </Content>
      </>
    </DashboardLayout>
  );
};

export default withSuspense(VerifyTauriInstallation);
