import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import type { Benchmark } from "@kuzco/models";
import { time } from "@kuzco/utils";

import useBenchmarks from "~/ui/hooks/useBenchmark.hook";
import useUser from "~/ui/hooks/useUser.hook";

const InnerTable = ({
  children,
  isSuperUser,
}: {
  children?: React.ReactNode;
  isSuperUser: boolean;
}) => {
  return (
    <Table variant="simple">
      <Thead
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.800"
        height="44px"
      >
        <Tr backgroundColor="gray.1000" borderColor="gray.800">
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
          >
            Created
          </Th>
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
          >
            Model Name
          </Th>
          <Th
            borderColor="gray.800"
            color="gray.200"
            textTransform="capitalize"
            letterSpacing="0px"
          >
            Average Tokens / Second
          </Th>
          {isSuperUser ? (
            <Th
              borderColor="gray.800"
              color="gray.200"
              textTransform="capitalize"
              letterSpacing="0px"
              width="100px"
            />
          ) : null}
        </Tr>
      </Thead>
      {children ? children : null}
    </Table>
  );
};

const MockTable = ({
  children,
  isSuperUser,
}: {
  children: React.ReactNode;
  isSuperUser: boolean;
}) => {
  return (
    <Box overflow="hidden" height="100%" width="100%">
      <InnerTable isSuperUser={isSuperUser} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 150px)"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
};

const Column = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td height="72px" borderColor="gray.800">
      <Text
        overflow="hidden"
        color="gray.200"
        fontSize="14px"
        lineHeight="20px"
        fontWeight="400"
        maxWidth="500px"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Text>
    </Td>
  );
};

const BenchmarkTable: React.FC<{ workerId: string }> = ({ workerId }) => {
  const { ref, inView } = useInView();
  const { isSuper } = useUser();
  const { listByWorker } = useBenchmarks(workerId);
  const [refetchCounter, setRefetchCounter] = React.useState<number>(60);

  const { data, refetch, fetchNextPage, isFetching, isLoading, isRefetching } =
    listByWorker;

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const filtered = React.useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.benchmarks)
        .filter((g): g is Benchmark => Boolean(g)) ?? [],
    [data],
  );
  const totalDBRowCount = data?.pages[0]?.total ?? 0;
  const totalFetched = filtered.length;

  React.useEffect(() => {
    if (inView && !isFetching && totalFetched < totalDBRowCount) {
      fetchNextPage().catch((e) => {
        console.error(e);
      });
    }
  }, [inView, fetchNextPage, totalFetched, totalDBRowCount, isFetching]);

  useInterval(() => {
    setRefetchCounter(60);
    refetch().catch((e) => {
      console.error(e);
    });
  }, 60000);

  useInterval(() => {
    setRefetchCounter((refetchCounter) => refetchCounter - 1);
  }, 1000);

  return (
    <TableContainer
      overflow="auto"
      border="1px solid"
      borderColor="gray.800"
      borderRadius="8px"
      backgroundColor="gray.1100"
    >
      <Box
        padding="24px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Text color="white" variant="20-semi">
            Benchmarks ({data?.pages[0]?.total})
          </Text>
        </Box>
        <Flex alignItems="center">
          <Text variant="14-reg" color="gray.400" marginRight="12px">
            Updating in {refetchCounter}s
          </Text>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => refetch()}
            isLoading={isRefetching}
          >
            Refresh
          </Button>
        </Flex>
      </Box>

      {(() => {
        if (isLoading && !filtered.length)
          return (
            <MockTable isSuperUser={isSuper}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="300px"
              >
                <Spinner />
              </Box>
            </MockTable>
          );
        if (!filtered.length) {
          return (
            <MockTable isSuperUser={isSuper}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="300px"
              >
                <Icon as={FiSearch} color="gray.400" fontSize="40px" />
                <Flex height="16px" />
                <Text color="gray.500" fontSize="18px" fontWeight="500">
                  No benchmarks yet.
                </Text>
              </Box>
            </MockTable>
          );
        }

        return (
          <InnerTable isSuperUser={isSuper}>
            <Tbody overflow="hidden">
              {filtered.map((benchmark, index) => {
                return (
                  <Tr
                    key={index}
                    ref={index === filtered.length - 10 ? ref : null}
                  >
                    <Column>
                      {time.format(
                        benchmark.createdAt.getTime(),
                        "f",
                        time.currentBrowserTimezone(),
                      )}
                    </Column>
                    <Column>{benchmark.model}</Column>
                    <Column>
                      {benchmark.tokensPerSecond
                        ? `${benchmark.tokensPerSecond.toFixed(2)} tok/s`
                        : "--"}
                    </Column>
                    {isSuper ? (
                      <Column>
                        <Link to={`/dashboard`}>
                          <Button variant="secondary" size="sm">
                            View
                          </Button>
                        </Link>
                      </Column>
                    ) : null}
                  </Tr>
                );
              })}
            </Tbody>
          </InnerTable>
        );
      })()}
      {filtered.length > 0 && isFetching ? (
        <Box
          flex="1"
          height="64px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Box>
      ) : null}
    </TableContainer>
  );
};

export default BenchmarkTable;
