import { Suspense } from "react";
import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";

import { LEARN_MORE_LINK } from "~/lib/const";
import { api } from "~/lib/trpc";
import CardsChart from "~/ui/components/metrics/Cards.chart";
import GenerationCountChart from "~/ui/components/metrics/GenerationCount.chart";
import TokenEarningsChart from "~/ui/components/metrics/TokenEarnings.chart";
import SystemStatus from "~/ui/components/SystemStatus";
import SystemTime from "~/ui/components/SystemTime";
import Card from "~/ui/elements/Card";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import PageLayoutSuspense from "~/ui/suspense/PageLayout.suspense";
import StatsSuspense from "~/ui/suspense/Stats.suspense";

const OverviewPageComponent = () => {
  const isMobile = useIsMobile();
  const [rpm] = api.metrics.rpm.useSuspenseQuery();
  const [data] = api.metrics.global.useSuspenseQuery();
  const [online] = api.instance.countOnline.useSuspenseQuery(undefined, {
    refetchInterval: 5000,
  });

  const [system] = api.system.get.useSuspenseQuery(undefined, {
    refetchInterval: 30000,
  });

  if (data === null) {
    return <OverviewPageSuspense />;
  }

  return (
    <>
      <Header>
        <Flex alignItems="center">
          <Title>Dashboard</Title>
          <Box width="16px" />
        </Flex>
        <SystemTime />
      </Header>
      <Content>
        {(() => {
          return (
            <>
              <Box height="24px" />
              <Card>
                <Box display="flex" alignItems="center">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    {!isMobile && (
                      <SystemStatus systemStatus={system.systemStatus} />
                    )}
                    <Flex alignItems="center">
                      <Text>
                        <Text as="span" color="green.400" fontWeight="500">
                          Epoch One
                        </Text>
                        &nbsp;has started
                      </Text>
                      <Link href={LEARN_MORE_LINK} target="_blank">
                        <Button variant="secondary" size="sm" marginLeft="8px">
                          Learn more
                        </Button>
                      </Link>
                    </Flex>
                  </Flex>
                </Box>
              </Card>
              <Box height="24px" />
              <CardsChart
                values={[
                  {
                    title: "Online Workers",
                    value: online.count ?? "",
                    subtitle: "Updated in real-time",
                  },
                  {
                    title: "Current RPM",
                    value: rpm,
                    subtitle: "Updated every minute",
                    isKuzcoValue: true,
                  },
                  {
                    title: "24H Network Payout",
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    value: data?.tokensLast24Hours ?? 0,
                    subtitle: "Updated every hour",
                    isKuzcoValue: true,
                  },
                ]}
              />
              <Box height="24px" />
              <TokenEarningsChart
                title="$KZO Points Payout"
                metric={data.tokensLast30DaysBarChart}
              />
              {/* <Box height="24px" /> */}
              {/* <AverageTPSChart
                title="Aggregate Network Average TPS"
                metric={data?.averageTPS}
              /> */}
              <Box height="24px" />
              <GenerationCountChart
                title="Aggregate Network Requests"
                // @ts-expect-error - fix later.
                metric={data.generationsLast24HoursLineChart}
              />
            </>
          );
        })()}
        <Box height="200px" />
      </Content>
    </>
  );
};

function OverviewPageSuspense() {
  return (
    <PageLayoutSuspense pageTitle="Dashboard">
      <StatsSuspense renderTopBar />
    </PageLayoutSuspense>
  );
}

export default function OverviewPage() {
  return (
    <Suspense fallback={<OverviewPageSuspense />}>
      <OverviewPageComponent />
    </Suspense>
  );
}
