import { atom, useAtom } from "jotai";
import { atomWithDefault } from "jotai/utils";

import { api } from "../../lib/trpc";
import useUser from "./useUser.hook";

const PAGE_SIZE = 25;

const superListUsersQueryOriginal = atomWithDefault<string>((get) =>
  get(atom("")),
);

export default function useSuper() {
  const { authenticate } = useUser();
  const [superListUsersQuery, setSuperListUsersQuery] = useAtom(
    superListUsersQueryOriginal,
  );
  const superListUsers = api.user.superList.useInfiniteQuery(
    {
      _id: superListUsersQuery,
      email: superListUsersQuery,
      firstName: superListUsersQuery,
      lastName: superListUsersQuery,
      discordId: superListUsersQuery,
      discordUsername: superListUsersQuery,
      username: superListUsersQuery,
    },
    {
      getNextPageParam: (_lastPage, allPages) => {
        return {
          pageSize: PAGE_SIZE,
          offset: allPages.length * PAGE_SIZE,
        };
      },
    },
  );
  const superImpersonateUser = api.user.superImpersonate.useMutation({
    onSuccess: (data) => {
      if (!data.user) {
        return;
      }

      authenticate(data);
      window.location.href = "/dashboard/overview";
    },
  });

  return {
    superListUsersQuery,
    setSuperListUsersQuery,
    superListUsers,
    superImpersonateUser,
  };
}
