import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import type { WorkerWithInstances } from "@kuzco/models";
import { workerStatus, WorkerStatusSchema } from "@kuzco/models";

import { DOWNLOAD_LINK } from "~/lib/const";
import Card from "~/ui/elements/Card";
import {
  useWorkerVersionStatus,
  VersionStatus,
} from "../../hooks/useVersion.hook";

type WorkerInfoProps = {
  worker: WorkerWithInstances;
  columns?: number;
  cards?: boolean;
};

const WorkerInfo: React.FC<WorkerInfoProps> = ({
  worker,
  columns = 2,
  cards,
}) => {
  const status = workerStatus(worker);
  const { version, versionStatus } = useWorkerVersionStatus(
    worker.instances.map((instance) => instance.info.version ?? ""),
  );

  const color = React.useMemo(() => {
    switch (status) {
      case WorkerStatusSchema.Enum.Online:
        return "green.500";
      case WorkerStatusSchema.Enum.Initializing:
        return "yellow.500";
      case WorkerStatusSchema.Enum.Offline:
        return "red.500";
      default:
        return "gray.500";
    }
  }, [status]);

  const versionInfo = React.useMemo(() => {
    switch (versionStatus) {
      case VersionStatus.Current:
        return {
          color: "white",
          tooltip: "All instances are running the current version.",
        };
      case VersionStatus.PartiallyOutdated:
        return {
          color: "yellow.500",
          tooltip:
            "Some instances are running an old version. Please update this worker to the latest version as soon as possible.",
        };
      case VersionStatus.FullyOutdated:
        return {
          color: "red.500",
          tooltip:
            "All instances are running an old version. Please update this worker to the latest version as soon as possible.",
        };
      case VersionStatus.NoVersion:
      default:
        return {
          color: "gray.500",
          tooltip: "No Instances Running. Version cannot be determined.",
        };
    }
  }, [versionStatus]);

  const fields = [
    {
      key: "Status",
      value: (
        <Box display="flex" alignItems="center">
          <Box
            backgroundColor={color}
            height="8px"
            width="8px"
            borderRadius="50%"
          />
          <Box width="8px" />
          {status}
          {status === WorkerStatusSchema.Enum.Initializing ? (
            <Box marginLeft="8px">
              <Spinner size="sm" color="yellow.500" />
            </Box>
          ) : null}
        </Box>
      ),
    },
    {
      key: "Live Instances",
      value: worker.instances.length.toString(),
    },
    {
      key: "Type",
      value: (() => {
        switch (worker.installType) {
          case "Desktop App":
            return "Desktop";
          case "Desktop Windows":
            return "Windows";
          case "CLI":
            return "CLI";
          case "Docker":
            return "Docker";
          default:
            return "CLI";
        }
      })(),
    },
    {
      key: "Epoch Rank",
      value: "One",
    },
    {
      key: "Reputation",
      value: "100%",
    },
    {
      key: "Version",
      value: (() => {
        return (
          <Tooltip label={versionInfo.tooltip}>
            <Box
              color={versionInfo.color}
              whiteSpace="nowrap"
              onClick={() => {
                window.open(DOWNLOAD_LINK, "_blank");
              }}
            >
              {version}
            </Box>
          </Tooltip>
        );
      })(),
    },
  ].filter((field): field is { key: string; value: string } => Boolean(field));

  return (
    <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={3}>
      {fields.map(({ key, value }, index: number) => {
        if (cards) {
          return (
            <React.Fragment key={index}>
              <GridItem colSpan={1}>
                <Card
                  padding="16px"
                  backgroundColor="gray.1000"
                  borderColor="gray.800"
                >
                  <Flex direction="column">
                    <Text
                      variant="12-semi"
                      color="gray.500"
                      as="span"
                      whiteSpace="nowrap"
                    >
                      {key}
                    </Text>
                    <Flex align="center">
                      <Text variant="18-semi" color="white" whiteSpace="nowrap">
                        {value}
                      </Text>
                    </Flex>
                  </Flex>
                </Card>
              </GridItem>
            </React.Fragment>
          );
        }

        return (
          <GridItem key={index} colSpan={1}>
            <Flex direction="column">
              <Text variant="12-bold" color="gray.500" as="span">
                {key}
              </Text>
              <Flex align="center">
                <Text variant="14-bold" color="white">
                  {value}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default WorkerInfo;
